import 'firebase/auth'
import 'firebase/database'

export const appName = 'startsandflops-6dc50'
export const botName = 'WorkLikeProBot'
export const yaMoneyCode = 410012008131074

export const config = {
	apiKey: 'AIzaSyBlAz5IZ2d7GxKwtcmFG-2XpNipbYgcM3E',
	authDomain: `${appName}.firebaseapp.com`,
	databaseURL: `https://${appName}.firebaseio.com`,
	projectId: appName,
	storageBucket: '',
	messagingSenderId: '1052454065528',
}
