import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import Typed from 'typed.js'
import cn from 'classnames'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firebaseConnect, getVal, isLoaded, isEmpty } from 'react-redux-firebase'
import _isEmpty from 'lodash/isEmpty'

import Buy from 'components/Buy'
import Input from 'components/Input'
import Loading from 'components/Loading'

import { sliderConfig, slideItems, pricesConfig } from './config'

import styles from './index.module.scss'

const productId = '-LIaFvWYpa9twiWsaAZY'
const titles = ['Добивайся целей', 'Учись и работай', 'Планируй', 'Живи и отдыхай']

const Button = props => <button {...props} />

class WorkLikePro extends Component {
	constructor(props) {
		super(props)

		this.state = {
			email: '',
			chosenTariff: 'cheap',
			sliders: {},
		}
		this.slick = {}
	}

	componentDidMount() {
		const options = {
			strings: titles,
			startDelay: 1300,
			typeSpeed: 100,
			backSpeed: 50,
			backDelay: 2000,
			loop: true,
			loopCount: 2,
		}

		this.typed = new Typed(this.el, options)
	}

	componentWillUnmount() {
		this.typed.destroy()
	}

	changeSlide = (position, sliderName) => {
		const { sliders } = this.state
		sliders[sliderName] = position
		this.setState({ sliders })
		this.slick[sliderName].slickGoTo(position)
	}

	renderSubscribeBlock = (props = {}) => {
		const { email, chosenTariff } = this.state
		const { product, redirect, location } = this.props
		const { search } = location
		const { placeholder = 'твоя почта', addonText = 'стать лучше за 690 ₽', tariff } = props

		if (!isLoaded(product)) {
			return <Loading />
		}
		if (isEmpty(product)) {
			return <p className={styles.error}>Что-то пошло не так, попробуйте зайти позже</p>
		}
		return (
			<Buy
				product={{ ...product, id: productId }}
				chosenTariff={tariff || chosenTariff}
				email={email}
				redirect={redirect}
				search={search}
			>
				<Input
					required
					maxLength={37}
					type="email"
					name="email"
					placeholder={placeholder}
					onChange={e => this.setState({ email: e.target.value })}
					value={email}
					addonAfter={
						<Button type="primary" htmltype="submit">
							{addonText}
						</Button>
					}
				/>
			</Buy>
		)
	}

	changeTariff = chosenTariff => {
		this.setState({ chosenTariff })
	}

	render() {
		const { sliders, chosenTariff } = this.state
		const { product = {} } = this.props
		const { prices = {} } = product

		return (
			<div className={styles.workLikeProLanding}>
				<Grid fluid>
					<div className={styles.headerImage}>
						<img src="/img/header.png" alt="header" />
					</div>

					<div className={styles.headerContentBlock}>
						<Row>
							<Col sm={12} smOffset={0} md={10} mdOffset={1} lg={8} xl={7}>
								<h5>
									курс по развитию
									<br />
									личной продуктивности
								</h5>

								<h2 className={styles.mainHeaderTitle}>
									<span
										ref={el => {
											this.el = el
										}}
									/>
									<br />
									как про
								</h2>

								<p>
									25&nbsp;уроков как стать лучше: успевать больше, реализовывать проекты, расти по карьерной
									лестнице, достаточно спать. Всегда за 690&nbsp;₽.
								</p>

								<div className={styles.sectionIndent} key="top">
									{this.renderSubscribeBlock({ tariff: 'cheap' })}
								</div>

								<p>
									Треть жизни мы тратим на сон. Остальные две трети — распределяем между работой, общением,
									хобби и отдыхом. В идеале хотелось бы успеть всё: построить карьеру, запустить бизнес,
									проводить больше времени с близкими, не забывать про спорт, и чтобы это всё не в ущерб сну.
								</p>

								<p className={styles.sectionIndent}>
									На практике всё идёт не по плану. Сваливаются незапланированные задачи, подводят коллеги и
									подрядчики, из-за того, что кто-то нас понял не так, сдвигаются все сроки. Или наоборот —
									больше положенного тратим на сериалы и соцсети, в итоге не успеваем за жизненными целями и
									приоритетами.
								</p>

								<h4>Что такое Ворк Лайк Про?</h4>

								<p className={styles.smallSectionIndent}>
									Это курс по подписке о том, как стать продуктивнее. В курсе делимся инструментами и
									подходами, которые позволяют воспитать в себе эффективность. Добавляем прикладные
									инструменты, даём шаблоны поведения и на выходе получаем собственную систему
									продуктивности. Вот 4&nbsp;основных мысли, которые транслируем на курсе:
								</p>
							</Col>
						</Row>
						<Row>
							<Col
								sm={12}
								smOffset={0}
								md={10}
								mdOffset={1}
								// sm={{ span: 24, offset: 0 }} md={{ span: 20, offset: 2 }}
							>
								<Row className={cn(styles.smallSectionIndent, styles.bigNumberBlock)}>
									<Col md={3} lg={2}>
										<h1 className={styles.bigNumber}>1</h1>
									</Col>
									<Col md={9} lg={10}>
										<q>
											Продуктивность — это&nbsp;не&nbsp;только
											<br />
											&nbsp;про результаты, но и&nbsp;про ощущения.
										</q>
										<p className="extra">
											Те, кто впервые берётся за управление собой, часто приходят к неприятному результату —
											начинают успевать больше, но чувствовать себя хуже. Мы верим, что продуктивность в первую
											очередь про ощущения. Иногда даже следует успевать меньше.
										</p>
									</Col>
								</Row>

								<Row className={cn(styles.smallSectionIndent, styles.bigNumberBlock)}>
									<Col md={9} lg={10}>
										<q>
											Личная продуктивность —&nbsp;это
											<br />
											&nbsp;не&nbsp;только&nbsp;про управление временем.
										</q>
										<p className="extra">
											Другое заблуждение — достаточно внедрить систему управления временем. На практике
											продуктивность шире: она и&nbsp;про бытовую коммуникацию, и&nbsp;про эмпатию, и&nbsp;про
											командную работу, и&nbsp;про переговоры, и&nbsp;про правильный сон.
										</p>
									</Col>
									<Col md={3} lg={2}>
										<h1 className={styles.bigNumber}>2</h1>
									</Col>
								</Row>

								<Row className={cn(styles.smallSectionIndent, styles.bigNumberBlock)}>
									<Col md={3} lg={2}>
										<h1 className={styles.bigNumber}>3</h1>
									</Col>
									<Col md={9} lg={10}>
										<q>Нужно быть готовым меняться.</q>
										<p className="extra">
											Продуктивность — это не арифметика и не английский язык. Не получится просто много раз
											повторить термин, чтобы он прижился. Надо работать, рефлексировать, делать выводы и
											внедрять в жизнь много нового.
										</p>
									</Col>
								</Row>

								<Row className={styles.bigNumberBlock}>
									<Col md={9} lg={10}>
										<q>
											Основа — не инструменты,&nbsp;
											<br />а майндсет.
										</q>
										<p className="extra">
											Но майндсет без инструментов не сработает. В курсе куча инструментов — от&nbsp;избитых,
											но важных, до наших авторских.
										</p>
									</Col>
									<Col md={3} lg={2}>
										<h1 className={styles.bigNumber}>4</h1>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>

					<div className={styles.howItWorkBlock}>
						<Row>
							<Col md={4} lg={3}>
								<div className={styles.howItWorkBlockImage}>
									<img src="/img/how-it-work.png" alt="How it work" />
								</div>
							</Col>
							<Col md={7} lg={7}>
								<h4>Как это работает?</h4>
								<p className="extra">
									Один раз в неделю приходит письмо-урок. В каждом уроке — подробный разбор темы, и домашнее
									задание на неделю. По некоторым заданиям мы предлагаем отчитаться — написать нам и
									поделиться результатами.
								</p>
								<p className="extra">
									В некоторых письмах рекомендуем книги или делимся видео по теме, которые сами перевели и
									озвучили. Всего в курсе 25&nbsp;уроков — 6&nbsp;месяцев работы над собой.
								</p>
							</Col>
						</Row>
					</div>

					<div className={cn(styles.listOfItemsBlock, styles.contentBlock)}>
						<h4>Какие темы разбираем в курсе:</h4>
						<ol>
							<Row className={styles.gutterRow}>
								<Col md={4} className={styles.gutterCol}>
									<Row>
										<Col xs={12}>
											<li>Введение в проактивность</li>
											<li>Образ мышления</li>
											<li>Критическое мышление</li>
											<li className={styles.active}>
												<a
													href="https://preview.mailerlite.com/u4t1j0"
													target="_blank"
													rel="noreferrer noopener"
												>
													Планируем результат с конца
												</a>
											</li>
											<li>Приоритезация</li>
											<li>Краткосрочное планирование</li>
											<li>Фишки планирования</li>
											<li>Мультитаскинг и правило трёх</li>
										</Col>

										<Col xs={12}>
											<div className={styles.listImage}>
												<img src="/img/folder-icon.png" alt="list" className={styles.first} />
											</div>
										</Col>
									</Row>
								</Col>
								<Col md={4} className={styles.gutterCol}>
									<Row>
										<Col xs={12}>
											<div className={styles.listImage}>
												<img src="/img/calendar-icon.png" alt="list" className={styles.second} />
											</div>
										</Col>

										<Col xs={12}>
											<li>Эмоциональный интеллект</li>
											<li>Эмпатия в работе с собой и другими</li>
											<li className={styles.active}>
												<a
													href="https://preview.mailerlite.com/m7x0f0"
													target="_blank"
													rel="noreferrer noopener"
												>
													7 правил общения
												</a>
											</li>
											<li>Баланс работы и жизни</li>
											<li>Оценка времени</li>
											<li>Продуктивность и коммуникация</li>
											<li>Занятость — враг продуктивности</li>
											<li>Привычки и утренняя рутина</li>
										</Col>
									</Row>
								</Col>
								<Col md={4} className={styles.gutterCol}>
									<Row>
										<Col xs={12}>
											<li>Выводим мозг из застоя</li>
											<li className={styles.active}>
												<a
													href="https://preview.mailerlite.com/a1k7u1"
													target="_blank"
													rel="noreferrer noopener"
												>
													Прокрастинация
												</a>
											</li>
											<li>Переговоры. Часть&nbsp;1</li>
											<li>Переговоры. Часть&nbsp;2</li>
											<li>Сон и внутренние силы</li>
											<li>Здоровье и активность</li>
											<li>Разбираемся со&nbsp;стрессом</li>
											<li>Гормоны счастья</li>
											<li>Персональный канбан</li>
										</Col>
										<Col xs={12}>
											<div className={styles.listImage}>
												<img src="/img/chat-icon.png" alt="list" className={styles.third} />
											</div>
										</Col>
									</Row>
								</Col>
							</Row>
						</ol>
					</div>

					<div className={cn(styles.stagesBlock, styles.contentBlock)}>
						<h4>Курс состоит из трёх этапов:</h4>

						<div>
							{Object.keys(slideItems).map((key, index) => {
								const sliderData = slideItems[key]
								const isLeftDirection = index % 2 === 0
								const sliderBlock = (
									<Col md={6} lg={7} className={styles.gutterCol}>
										<Slider
											ref={ref => {
												this.slick[key] = ref
											}}
											{...sliderConfig}
											beforeChange={(oldIndex, newIndex) => {
												this.changeSlide(newIndex, key)
											}}
											className={styles.slider}
										>
											{sliderData.elements.map(item => (
												<div key={`${key}-${item.img}`} className={styles.sliderItem}>
													<img src={`/img/${item.img}.png`} alt={item.title} />
												</div>
											))}
										</Slider>
									</Col>
								)
								const textBlock = (
									<Col md={6} lg={5} className={styles.gutterCol}>
										<b>{sliderData.title}</b>
										<p className="extra">{sliderData.description}</p>

										<div className={styles.buttonsBlock}>
											{sliderData.elements.map((item, i) => (
												<div
													className={cn(
														styles.sliderNavItem,
														sliders[key] === i || (sliders[key] === undefined && i === 0) ? styles.active : ''
													)}
													key={item.title}
													onClick={e => {
														e.preventDefault()
														this.slick[key].slickGoTo(i)
													}}
													onKeyPress={e => {
														e.preventDefault()
														this.slick[key].slickGoTo(i)
													}}
													role="button"
													tabIndex="0"
												>
													{item.title}
												</div>
											))}
										</div>
									</Col>
								)

								if (isLeftDirection)
									return (
										<Row key={key} className={cn(styles.gutterRow, styles.sectionIndent)}>
											{textBlock}
											{sliderBlock}
										</Row>
									)
								return (
									<Row
										key={key}
										className={cn(styles.gutterRow, styles.sectionIndent, styles.leftDirection)}
									>
										{sliderBlock}
										{textBlock}
									</Row>
								)
							})}
						</div>
					</div>

					<div className={cn(styles.faqBlock, styles.contentBlock)}>
						<Row>
							<Col md={10} mdOffset={1} lg={8} lgOffset={1} xl={7} xlOffset={1}>
								<h4>Вопросы и ответы:</h4>
								<div className={styles.smallSectionIndent}>
									<q>А вы кто?</q>
									<p>
										Три года назад мы запустили авторское СМИ по профессиональному и карьерному росту. Два
										года назад — учредили Фонд по поддержке талантов, где учим перспективных студентов
										добиваться большего. В этом году — участвовали в запуске платформы для малого бизнеса.
									</p>
									<p>
										Каждый день мы сталкиваемся с теми, у кого в голове много идей, но не хватает времени даже
										на бытовые задачи, и помогаем им с этим справиться.
									</p>
								</div>
								{/* <div className={styles.smallSectionIndent}>
									<q>Можно получить все уроки сразу?</q>
									<p>Все сразу нельзя — эффект от курса будет хуже.</p>
								</div> */}
								<div className={styles.smallSectionIndent}>
									<q>Что если я не сделаю домашние задания?</q>
									<p>
										Выполнение домашних заданий не является обязательным условием курса — особенно сложные
										можно растягивать на более долгий срок, к&nbsp;каким-то возвращаться позже. Если не делать
										домашние задания совсем, то стать продуктивнее не получится.
									</p>
								</div>
								<div className={styles.smallSectionIndent}>
									<q>Что делать, если я что-то не&nbsp;пойму?</q>
									<p>
										Можно написать нам на почту, мы поясним и поможем внедрить. Также нам можно написать по
										поводу ошибок или некорректных ссылок — мы постоянно обновляем курс, но что-то можем
										упустить.
									</p>
								</div>
								<div className={styles.smallSectionIndent}>
									<q>Можно купить курс вскладчину или на несколько человек?</q>
									<p>
										Нет, курс предназначен только для индивидуального использования. Отдельные уроки можно
										пересылать тем, кому они могут быть полезны, но распространять курс нельзя.
									</p>
								</div>
								<div>
									<q>А где оферта?</q>
									<p>Эта страница и есть оферта. Подписка на курс автоматически означает её принятие.</p>
								</div>
							</Col>
						</Row>
					</div>

					<div className={cn(styles.buyBlock, styles.contentBlock, styles.sectionIndent)}>
						<div className={styles.textBlock}>
							<h3>Стать&nbsp;продуктивнее</h3>

							{_isEmpty(prices) ? (
								<h1>за&nbsp;690&nbsp;₽</h1>
							) : (
								<div className={styles.tariffsBlock}>
									{Object.entries(prices).map(([key, value]) => {
										const { value: price, slug } = value
										const { caption, desc } = pricesConfig[slug]
										return (
											<div
												key={`price-${key}`}
												className={cn(styles.tariff, chosenTariff === slug && styles.active)}
												onClick={() => this.changeTariff(slug)}
												onKeyPress={() => this.changeTariff(slug)}
												role="button"
												tabIndex="0"
											>
												<div className={styles.caption}>{caption}</div>
												<div className={styles.price}>за {price}₽</div>
												<div className={styles.desc}>{desc}</div>
											</div>
										)
									})}
								</div>
							)}
						</div>
						<div className={styles.buyButton} key="bottom">
							{this.renderSubscribeBlock({
								placeholder: 'work@like.pro',
								addonText: 'подписаться на курс',
							})}
						</div>
						<div className={styles.policyBlock}>
							<p>
								Подписываясь, ты соглашаешься на обработку&nbsp;
								<br />
								персональных данных.
							</p>
							<a
								href="https://docs.google.com/document/d/1Bq3AhhW7aZM3E6oZ-P-YzW9dz2F6h24K_WWDUjS17Js/edit"
								target="_blank"
								rel="noreferrer noopener"
							>
								Условия
							</a>
						</div>
					</div>

					<div className={cn(styles.crewBlock, styles.contentBlock)}>
						<h5>в фильме снимались</h5>
						<Row className={styles.gutterRow}>
							<Col sm={6} className={styles.gutterCol}>
								<i>Написал все согласные и знаки препинания</i>
							</Col>
							<Col sm={6} className={styles.gutterCol}>
								<p>Вячеслав Уфимцев</p>
							</Col>
						</Row>
						<Row className={styles.gutterRow}>
							<Col sm={6} className={styles.gutterCol}>
								<i>Написал все гласные и&nbsp;пробелы</i>
							</Col>
							<Col sm={6} className={styles.gutterCol}>
								<p>Алексей Чекалин</p>
							</Col>
						</Row>
						<Row className={styles.gutterRow}>
							<Col sm={6} className={styles.gutterCol}>
								<i>Написал вообще весь код</i>
							</Col>
							<Col sm={6} className={styles.gutterCol}>
								<p>Александр Штыков</p>
							</Col>
						</Row>
						<Row className={styles.gutterRow}>
							<Col sm={6} className={styles.gutterCol}>
								<i>Нарисовал все круги и прямоугольники</i>
							</Col>
							<Col sm={6} className={styles.gutterCol}>
								<p>Никита Вилков</p>
							</Col>
						</Row>
					</div>
				</Grid>
			</div>
		)
	}
}

WorkLikePro.propTypes = {
	// Не могу задать по умолчанию, т.к. isLoaded/isEmpty
	// из react-redux-firebase работают странно
	product: PropTypes.object,
	redirect: PropTypes.func,
	location: PropTypes.object.isRequired,
}

WorkLikePro.defaultProps = {
	redirect: () => {},
}

export default compose(
	firebaseConnect({ product: `products/${productId}` }),
	connect(({ firebase }) => ({
		product: getVal(firebase, `data/products/${productId}`),
	}))
)(WorkLikePro)
