import { createStore } from 'redux'

import firebase from 'firebase/app'

import { config as firebaseConfig } from 'configs/app'

import { rootReducer } from './index'

// react-redux-firebase config
const rrfConfig = {
	userProfile: 'users',
	// useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

firebase.initializeApp(firebaseConfig)

// Create store with reducers and initial state
const initialState = {}
const store = createStore(rootReducer, initialState)

const rrfProps = {
	firebase,
	config: rrfConfig,
	dispatch: store.dispatch,
	// createFirestoreInstance // <- needed if using firestore
}

export { store, rrfProps }
