import React from 'react'

const pricesConfig = {
	cheap: {
		caption: 'постепенно',
		desc: <span>уроки будут приходить  раз&nbsp;в&nbsp;неделю</span>,
	},
	expensive: {
		caption: 'сразу',
		desc: <span>все уроки придут  в&nbsp;одном&nbsp;письме</span>,
	},
}

const sliderConfig = {
	dots: true,
	arrows: false,
	infinite: true,
	speed: 300,
	slidesToShow: 1,
	slidesToScroll: 1,
}

const slideItems = {
	base: {
		title: 'База',
		description:
			'На первом разбираем фундамент: проактивность, мышление, базовую коммуникацию. На этом этапе работаем с собственным мышлением: как мы воспринимаем мир и относимся к вещам и событиям.',
		elements: [
			{
				title: 'о когнитивных искажениях',
				img: 'slider-item-1-1',
			},
			{
				title: 'о способе мышления',
				img: 'slider-item-1-2',
			},
			{
				title: 'о критическом мышлении',
				img: 'slider-item-1-3',
			},
		],
	},
	tools: {
		title: 'Инструменты',
		description:
			'На втором этапе говорим о планировании, приоритезации, много внимания уделяем коммуникации. Это самая суровая часть курса — придётся много практиковаться, чтобы успеть уложить материал.',
		elements: [
			{
				title: 'о долгосрочном планировании',
				img: 'slider-item-2-1',
			},
			{
				title: 'о влиянии на себя и других',
				img: 'slider-item-2-2',
			},
			{
				title: 'о приоритетах',
				img: 'slider-item-2-3',
			},
			{
				title: 'об оценке времени',
				img: 'slider-item-2-4',
			},
		],
	},
	lifehacks: {
		title: 'Лайфхаки',
		description:
			'Третья часть — фишки и инструменты, которые помогают уложить всё пройденное, прокачать прикладные навыки. Здесь много фреймворков и шаблонов.',
		elements: [
			{
				title: 'об организации работы',
				img: 'slider-item-3-3',
			},
			{
				title: 'о креативности',
				img: 'slider-item-3-1',
			},
			{
				title: 'о прокрастинации',
				img: 'slider-item-3-2',
			},
		],
	},
	practice: {
		title: 'Практика',
		description:
			'К каждому уроку есть домашка. Некоторые можно сделать самостоятельно за пару вечеров, а для некоторых придётся взаимодействовать с другими.',
		elements: [
			{
				title: 'задание по планированию',
				img: 'slider-item-4-1',
			},
			{
				title: 'задание по приоритетам',
				img: 'slider-item-4-2',
			},
			{
				title: 'задание по фокусировке',
				img: 'slider-item-4-3',
			},
			{
				title: 'задание по рутинным задачам',
				img: 'slider-item-4-4',
			},
		],
	},
}

export { sliderConfig, slideItems, pricesConfig }
