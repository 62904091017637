import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Input extends Component {
	render() {
		const { addonAfter, ...rest } = this.props
		const component = <input {...rest} />

		if (addonAfter)
			return (
				<div className="input-wrapper">
					{component}
					<span className="group-addon">{addonAfter}</span>
				</div>
			)
		return component
	}
}

Input.propTypes = {
	addonAfter: PropTypes.node,
}

Input.defaultProps = {
	addonAfter: null,
}

export default Input
