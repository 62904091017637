import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'

import { store, rrfProps } from 'modules/store'

import App from './containers/App'

import './styles/index.scss'

render(
	<Provider store={store}>
		<ReactReduxFirebaseProvider {...rrfProps}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</ReactReduxFirebaseProvider>
	</Provider>,
	document.getElementById('root')
)
