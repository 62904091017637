import React, { PureComponent } from 'react'

import styles from './index.module.scss'

class Loading extends PureComponent {
	render() {
		return <div className={styles.loader}>Loading...</div>
	}
}

export default Loading
