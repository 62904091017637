import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { yaMoneyCode } from 'configs/app'

import styles from './index.module.scss'

const codes = {
	cheap: 'cp',
	expensive: 'ex',
}

class Buy extends PureComponent {
	render() {
		const { product, chosenTariff, email, children, search } = this.props
		const [, price] = Object.entries(product.prices).find(([, value]) => value.slug === chosenTariff)

		const comment = `Покупка доступа: "${product.title}"`
		let uniqLabel = `${email}&&${product.id}&&${codes[chosenTariff]}${search ? `&&${search}` : ''}`
		const successURL = `${window.location.host}`

		// Ограничения яндекса
		// https://yandex.ru/dev/money/doc/payment-buttons/reference/forms-docpage/
		// Средняя длина, которую я оставляю на email - 37 символов
		if (uniqLabel.length >= 64) uniqLabel = uniqLabel.slice(0, 63)

		return (
			<form
				method="POST"
				action="https://money.yandex.ru/quickpay/confirm.xml"
				className={styles.buyComponent}
			>
				<input type="hidden" name="receiver" value={yaMoneyCode} />
				<input type="hidden" name="quickpay-form" value="shop" />
				<input type="hidden" name="targets" value={comment} />
				<input type="hidden" name="paymentType" value="AC" />
				<input type="hidden" name="sum" value={price.value || 0} data-type="number" />
				{/* === not required === */}
				<input type="hidden" name="formcomment" value={comment} />
				<input type="hidden" name="short-dest" value={comment} />
				<input type="hidden" name="label" value={uniqLabel} />
				<input type="hidden" name="successURL" value={successURL} />
				{children}
			</form>
		)
	}
}

Buy.propTypes = {
	chosenTariff: PropTypes.string.isRequired,
	product: PropTypes.object.isRequired,
	children: PropTypes.element,
	email: PropTypes.string,
	search: PropTypes.string,
}

Buy.defaultProps = {
	email: '',
	children: null,
	search: '',
}

export default Buy
